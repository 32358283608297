function Header() {
    return (
        <>
            <div className="header">
                <div className="logotype">
                    <a href="/">
                        <h1>
                            kcalorizer
                        </h1>
                    </a>
                </div>
            </div>
        </>
    )
}

export default Header